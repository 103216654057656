export default `

**ENTRE :**
<br>

{{LASTNAME}} {{FIRSTNAME}} {{SIRET}}, demeurant {{FULLADDRESS}}, {{EMAIL}}, {{PHONE}};<br>
<div style="text-align: right;">(Ci-après dénommé(e) le « CLIENT ») ;</div><br>

**ET**


SÉSAME, SARL immatriculée au RCS de Paris sous le n°790 197 156, dont le siège social est situé 39 Boulevard de Magenta, 75010 PARIS représentée par son représentant légal, domicilié en cette qualité audit siège ;
<br>
<div style="text-align: right;">(Ci-après dénommée « SÉSAME ») ;</div>


<div style="text-align: right;">Ci-après désigné(e)s collectivement les "Parties" et individuellement une "Partie".</div>


**ÉTANT PRÉALABLEMENT EXPOSÉ QUE :**
<br><br>
Le PRESTATAIRE dispose de locaux situés au 39 boulevard Magenta - 75010 PARIS, escalier A, 1er étage.
<br><br>
Le CLIENT est un professionnel indépendant exerçant à titre personnel ou sous forme de sociétés, et souhaite bénéficier des locaux du PRESTATAIRE afin :

- d’exercer son activité ;
- de recevoir sa clientèle.

Le CLIENT exerce la profession de {{JOBTITLE}},

À ce titre, il souhaite exercer son activité ou une partie de son activité au sein des locaux du PRESTATAIRE.

C’est dans ce contexte que les PARTIES se sont rapprochées pour définir les termes et conditions applicables (ci-après le « Contrat »).

CECI AYANT ÉTÉ EXPOSÉ, LES PARTIES ONT CONVENU CE QUI SUIT :

##  ARTICLE 1 OBJET

Le Contrat a pour objet la mise à disposition des locaux de SÉSAME, aux fins d’exercice de son activité par le CLIENT.

Cette mise à disposition est une simple fourniture de moyens.


## ARTICLE 2 DOCUMENTS CONTRACTUELS

Le Contrat est constitué, par ordre de préséance décroissant :
- De ou des « Annexe(s) – Forfait » ;
- Des présentes Conditions générales ;
- Du Règlement intérieur de SÉSAME ci-joint en annexe ;
à l’exclusion de tout autre document (ci-après ensemble « le Contrat »). Il remplace et annule tout engagement oral ou écrit antérieur relatif à l'objet du Contrat.


## ARTICLE 3 MISE À DISPOSITION DE MOYENS (LOCAL, MATÉRIEL ET SERVICES)

### 3.1 Périmètre des prestations

SÉSAME met à disposition du CLIENT, dans ses locaux, une salle individuelle et le matériel nécessaire à l’exercice de sa profession, à savoir notamment : bureau, fauteuils, table de massage, tapis de sol, futon, couvertures, coussins, hi-fi…

SÉSAME propose au CLIENT :

- Un espace accueil : une salle d’attente et un accueil physique de la clientèle du CLIENTen fonction des horaires fixés dans le règlement intérieur joint en ANNEXE 1 des présentes ;
- Des sanitaires : au service du CLIENT et de sa clientèle ;
- Une douche : au service du CLIENT et de sa clientèle (étant entendu que le passage par la salle d’attente peut se faire seulement en tenue de ville) ;
- Un service bar : accessible au CLIENT et à sa clientèle, étant précisé que :
- le bar ne vendra que des boissons non-alcoolisées ;
- les tarifs seront affichés ;
- le règlement des consommations se fera directement auprès du personnel de SÉSAME ;
- Un espace cuisine : Le CLIENT en aura la jouissance pour ses déjeuners et/ou ses pauses dans le respect des dispositions du règlement intérieur joint en ANNEXE 1 des présentes. Cet espace est réservé au CLIENT et sa clientèle ne pourra y accéder.

Il est précisé que le CLIENT a accès à l’ensemble de ces espaces et services uniquement lors des heures valablement réservées par le CLIENT auprès de SÉSAME.

### 3.2 Modalités des prestations

Aux fins de bénéficier d’une salle individuelle et de l’ensemble des prestations proposées par SÉSAME, le CLIENT doit avoir souscrit à un forfait et réserver ses heures sur l’agenda en ligne de SÉSAME.

SÉSAME se réserve le droit de modifier le choix de salle du CLIENT pour ses besoins d’organisation interne.

Le(s) forfait(s) souscrit(s) par le CLIENT viennent en annexe du présent contrat (les « Annexe(s) - Forfait »).

SÉSAME assure l’accueil du CLIENT et de sa clientèle dans ses locaux sur les heures d’affluences. En l’absence d’accueil physique aux heures réservées, le CLIENT recevra par mail l’attribution d’un cabinet et le code de la porte d’entrée du centre (au premier étage) et fera alors son affaire de l’accueil de sa clientèle pour les besoins des rendez-vous pris avec celle-ci.

SÉSAME pourra, quand il le souhaite, fermer l’établissement pour période de vacances. Il s’engage à informer le CLIENT au moins 3 (trois mois) avant les périodes de fermeture par courriel à l’adresse électronique fournie par le CLIENT. L’échéance du forfait du CLIENT sera alors reportée d’autant.

## ARTICLE 4 INDÉPENDANCE DES PARTIES

Il est précisé que le présent Contrat ne pourra jamais conférer au CLIENT des droits relevant d’une jouissance exclusive de locaux, ni être considérée comme une location de locaux commerciaux ni comme une sous-location, même si la durée et la fréquence d’occupation peuvent être importante  ou si le chiffre d’affaire principal du CLIENT est réalisé dans le centre du PRESTATAIRE.

Le CLIENT n’a pas autorisation à fixer son siège administratif ou son siège social dans les locaux mis à sa disposition. À ce titre, SÉSAME ne pourra être tenu responsable de la non réception pour quelques raisons que ce soit, de toute notification ou acte signifiés au CLIENT dans les locaux.

SÉSAME n’a pas non plus vocation à exercer l’activité des professionnels pour lesquels les locaux sont mis à disposition et ne pourra être recherché de ce chef.

SÉSAME et le CLIENT reconnaissent expressément qu’ils sont et demeureront des professionnels indépendants pendant toute la durée du contrat.

À ce titre :

- le CLIENT et son éventuel personnel ne pourra en aucun cas être assimilé juridiquement à un salarié de SÉSAME et réciproquement ;
- le Contrat exclut expressément toute volonté de conclure, sous quelque forme que ce soit, une relation hiérarchique ou d’établir un quelconque lien de subordination d’une Partie à l’égard de l’autre ou du personnel de l’une des Parties à l’égard de l’autre ;
- chaque Partie est seule tenue du paiement de ses propres charges sociales et différentes taxes ;
- le présent contrat exclut expressément tout affectio societatis, en conséquence chaque partie est indépendante et agit uniquement en son nom et pour son compte.

## ARTICLE 5 DÉCLARATIONS ET OBLIGATIONS DU CLIENT

Le CLIENT déclare :

- avoir la capacité et les diplômes requis à l’exercice de sa profession et exercer en conformité avec la réglementation et la législation applicable ;
- disposer de sa pleine capacité civile et commerciale pour s'engager au présent acte ;
- exercer son activité sous le régime "{{LEGALSTATUS}}".
- être assuré au titre de la responsabilité civile professionnelle, dommage aux biens et celle de ses préposés auprès d’une compagnie notoirement solvable pour l’exercice de sa profession même dans les lieux autres que son siège social ou administratif et s’engage à fournir un justificatif à SÉSAME à première demande.


Le CLIENT s’engage à :

- fournir à SÉSAME un document justifiant de son activité et de son statut juridique sur simple demande ;
- garantir SÉSAME contre tout recours ou action, direct(e) ou indirect(e),  dont il pourrait faire l’objet du fait du CLIENT ou de l’activité de ce dernier ;
- respecter tous les textes légaux et règlementaires en vigueur en matière de législation du travail et législation fiscale ;
- se conformer au règlement intérieur joint en ANNEXE 1 des présentes et aux consignes de sécurité applicables chez SÉSAME.

Le CLIENT fera son affaire personnelle de la gestion des retards éventuels de sa clientèle, ou des annulations de rendez-vous sans que la responsabilité de SÉSAME ne puisse être mise en cause.

Dans tous les cas, le CLIENT s’engage à ne pas dépasser l’heure réservée et à libérer le cabinet à la fin de son créneau.

Le CLIENT restera redevable du coût d’occupation de la salle même en cas de retard, de défaillance ou d’annulation de son client, de lui-même ou de ses préposés, si ces annulations surviennent en dehors du cadre prévu et autorisé par son forfait.

Le CLIENT s’engage à rendre le matériel mis à disposition en bon état et demeure responsable des dégâts éventuels. Le CLIENT devra signaler au préalable toutes difficultés à SÉSAME ou à son représentant présent dans les lieux sur l’état du matériel ou de la salle.

La qualité des relations individuelles constituant une condition déterminante du bon déroulement de la prestation, la désignation des collaborateurs ou de remplaçants du CLIENT fera l’objet d’une approbation préalable écrite de SÉSAME.

Étant précisé que le remplaçant ou le collaborateur désigné devra être un salarié du CLIENT, avoir les mêmes compétences professionnelles et bénéficier des mêmes assurances que le CLIENT. Il restera sous l’entière responsabilité du CLIENT.


## ARTICLE 6 RESPONSABILITÉ

L’obligation pesant sur SÉSAME est une obligation de moyens. Il s’engage à ce titre à apporter tout le soin possible à l’exécution de ses obligations.

Chacune des Parties ne sera tenue que de la réparation des conséquences pécuniaires des seuls dommages directs et prévisibles liés à des manquements à ses obligations au titre du présent contrat.

SÉSAME ne pourra en aucune circonstance encourir de responsabilité au titre des pertes ou dommages indirects ou imprévisibles du CLIENT ou des tiers, ce qui inclut notamment tout gain manqué, perte, préjudice commercial, perte de chiffre d'affaires ou de bénéfice, perte de clientèle, perte d'une chance, en relation ou provenant de l’inexécution ou de l’exécution fautive des prestations.

## ARTICLE 7 PROMOTION ET COMMUNICATION

SÉSAME autorise le CLIENT à utiliser l’adresse « 39 Boulevard de Magenta, 75010 » sur ses supports de communication (notamment carte de visite, flyer, site internet ou autres supports existants ou à venir). La clientèle du CLIENT doit cependant être informée que SÉSAME est une entité indépendante et n’est pas en mesure de prendre des rendez-vous au nom et pour le compte du CLIENT.

Il est expressément convenu que les PARTIES pourront citer leur collaboration pour toutes actions de publicité ou d’offre commerciale de leurs activités respectives. Cependant, si la communication projetée va au-delà de la seule mention de collaboration, la partie qui entend communiquer devra soumettre le contenu de sa communication (communiqué de presse ou tout autre élément promotionnel et/ou de communication) à l’accord préalable et écrit de l’autre partie.

SÉSAME propose au CLIENT d'écrire un texte sur sa pratique à insérer dans un classeur de présentation à l'accueil du centre Sésame. Ce texte, mis en page par SÉSAME, fera l'objet d'un contrôle et pourra être modifié par SÉSAME s'il estime qu'il n'est pas conforme à la législation.

Le CLIENT s’engage à respecter dans ses documents et communications les articles L. 5122-1 et suivants du Code de la santé publique et notamment l’article L. 5122-15 qui dispose  « La publicité ou la propagande, sous quelque forme que ce soit, relative aux objets, appareils et méthodes, présentés comme favorisant le diagnostic, la prévention ou le traitement des maladies, des affections relevant de la pathologie chirurgicale et des dérèglements physiologiques, le diagnostic ou la modification de l'état physique ou physiologique, la restauration, la correction ou la modification des fonctions organiques, peut être interdite par l'Agence nationale de sécurité du médicament et des produits de santé, lorsqu'il n'est pas établi que lesdits objets, appareils et méthodes possèdent les propriétés annoncées. L'Agence nationale de sécurité du médicament et des produits de santé peut aussi soumettre cette publicité ou propagande à l'obligation de mentionner les avertissements et précautions d'emplois nécessaires à l'information du consommateur. »


## ARTICLE 8 CONFIDENTIALITÉ

Chacune des Parties s’oblige à :

- tenir confidentielles toutes les informations qu’elle recevra de l’autre Partie ;
- ne pas divulguer les informations confidentielles de l’autre Partie à un tiers quelconque, autre que des employés ou agents ayant besoin de les connaître ;
- n’utiliser les informations confidentielles de l’autre Partie qu’à l’effet d’exercer ses droits et de remplir ses obligations aux termes du Contrat.

Nonobstant ce qui précède, aucune des Parties n’aura d’obligation quelconque à l’égard d’informations qui :

- seraient tombées ou tomberaient dans le domaine public indépendamment d’une faute par la Partie les recevant ;
- seraient développées à titre indépendant par la Partie les recevant ;
- seraient connues de la Partie les recevant avant que l’autre Partie ne les lui divulgue ;
- seraient légitimement reçues d’un tiers non soumis à une obligation de confidentialité ;
- devraient être divulguées en vertu de la loi ou sur ordre d’un tribunal (auquel cas elles ne devront être divulguées que dans la mesure requise et après en avoir prévenu par écrit la Partie les ayant fournies).

Les obligations des Parties à l’égard des informations confidentielles demeureront en vigueur pendant toute la durée du Contrat et aussi longtemps, après son terme, que les informations concernées demeureront confidentielles pour la Partie les divulguant.

## ARTICLE 9 DURÉE

Le Contrat prend effet à la date de signature des présentes.

Sous réserve des périodes couvertes par les forfaits souscrits par le CLIENT, le Contrat pourra être résilié par l’une ou l’autre des Parties par lettre recommandée avec accusé de réception avec un préavis minimal de 2 (deux) mois.

## ARTICLE 10 NON SOLLICITATION ET NON DÉBAUCHAGE

Le CLIENT s’engage, pendant la durée du contrat et 6 (six) mois après la fin du contrat, que ce soit directement ou indirectement, à ne pas procéder à des sollicitations et des débauchages du personnel de SÉSAME, sauf accord ponctuel et préalable de cette dernière.

Dans le cas où il ne respecterait pas cette obligation il s’engage à verser à SÉSAME à titre de clause pénale, une indemnité correspondant à 12 (douze) mois des appointements bruts du collaborateur concerné, basée sur le salaire du mois précédant son départ.

Le CLIENT s’engage, pendant la durée du contrat et 6 (six) mois après la fin du contrat, que ce soit directement ou indirectement, à ne pas procéder à des sollicitations et des débauchages de la clientèle des autres clients de SÉSAME.

## ARTICLE 11 CESSION ET TRANSMISSION DU CONTRAT

Le présent contrat étant conclu intuitu personae, les droits et obligations en résultant ne pourront être transférés par le CLIENT à quelque titre et sous quelque forme que ce soit (et notamment décès, incapacité, cession de fonds de commerce, apport en société, cession de titres) et à quelque personne que ce soit, sans l'accord préalable et écrit de SÉSAME.

En cas de non-respect, SÉSAME sera en droit de résilier le Contrat aux torts exclusifs du CLIENT et sans préjudice de tous dommages et intérêts.

Le CLIENT pourra notifier sa demande de transmission du Contrat par lettre recommandée avec accusé de réception présentant le successeur pressenti et l’engagement de ce dernier à accepter le Contrat sans réserve. A compter de la réception de la notification, SÉSAME disposera d’un délai de 2 (deux) mois pour notifier sa réponse au CLIENT. A défaut de réponse dans le délai, l’acceptation sera réputée refusée.

Il est également précisé que les forfaits souscrits par le CLIENT sont nominatifs et ne pourront être cédés ou transmis à quel que titre que ce soit.


## ARTICLE 12 RÉSILIATION

En cas de manquement à l’une des obligations prévues au Contrat, la Partie créancière de l’obligation met en demeure la Partie débitrice de s’exécuter par lettre recommandée avec accusé de réception. La Partie débitrice doit ensuite s’exécuter dans les quinze (15) jours sans quoi l’autre Partie peut demander la résiliation de plein droit du Contrat et ce sans préjudice de tous dommages et intérêts.

En cas de résiliation pour faute du Client, ce dernier est tenu au paiement de l’intégralité du Forfait souscrit en cours d’exécution, et ce même si la totalité des heures n’ont pu être effectivement utilisées.


## ARTICLE 13 DISPOSITIONS GÉNÉRALES

### 13.1 Intégralité du Contrat

Le Contrat contient l'intégralité des obligations des Parties.

Les dispositions du Contrat sont exclusives de toutes autres. Elles annulent et remplacent toutes propositions, accords ou protocoles et prévalent sur toutes autres communications entre les Parties, se rapportant à l'objet du Contrat, faites ou non pendant son exécution.

Aucune indication, aucun document, ne pourra engendrer des obligations non comprises dans le Contrat, s'ils n'ont fait l'objet d'un avenant signé par les Parties.

Les annexes et avenants ultérieurs éventuels font partie intégrante du Contrat et sont soumis à l'ensemble des dispositions qui le régissent.


### 13.2 Protection des données à caractère personnel

Dans le cadre de leurs relations contractuelles, les Parties s’engagent à respecter la réglementation en vigueur applicable au traitement de Données à caractère personnel et, en particulier, le règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 (RGPD) ainsi que la Loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.


### 13.3 Bonne foi

Les PARTIES s’engagent à toujours se comporter, à l’égard de l’autre, comme un partenaire loyal et de bonne foi.

Chacune des PARTIES devra porter à la connaissance de l’autre toutes informations utiles ou toutes difficultés dans leurs relations.

Le présent contrat a été conclu librement entre les PARTIES, lesquelles s'engagent à l'exécuter dans toutes ses dispositions de parfaite bonne foi.


### 13.4 Force majeure

Aucune des Parties aux présentes ne pourra être tenue responsable de l'inexécution, des manquements ou retards pris dans l'exécution de l'une quelconque de ses obligations qui seraient dues à la survenance d'un cas de force majeure tel que défini par l’article 1218 du Code civil ou tel que raisonnablement non maîtrisable par l’une des Parties.

La force majeure suspend les obligations nées du Contrat pendant toute la durée de son existence.


### 13.5 Tolérance

Le fait pour l'une des Parties de ne pas se prévaloir d'un manquement par l'autre Partie, à l'une quelconque des obligations du présent Accord, ne saurait être interprété pour l'avenir comme une renonciation à l'obligation en cause.


### 13.6 Nullité partielle

Si une ou plusieurs stipulations du présent Accord sont tenues pour non valides ou déclarées telles en application d'une loi, d'un règlement ou à la suite d'une décision définitive d'une juridiction compétente, les autres stipulations garderont toute leur force et leur portée, étant précisé que les Parties pourront, d'un commun accord, convenir de remplacer la ou les stipulations invalidées.


### 13.7 Élection de domicile

Pour l'exécution des présentes ainsi que de leurs suites, les Parties font respectivement élection de domicile en leurs sièges sociaux indiqués en tête des présentes. Toute modification du siège social ou de l'adresse de l'une des Parties ne sera opposable à l'autre Partie que huit jours calendaires après lui avoir été dûment notifiée.


# ARTICLE 14 LOI APPLICABLE ET LITIGES

Le présent Accord est régi, interprété et appliqué conformément au droit français.

Les Parties conviennent de faire leur possible pour tenter de résoudre à l’amiable les désaccords susceptibles de résulter de l’interprétation ou de l’exécution du présent Accord.

À DEFAUT DE RÉSOLUTION AMIABLE, TOUTES DIFFICULTÉS RELATIVES À LA VALIDITÉ, L’INTERPRÉTATION OU L’EXÉCUTION DE L’ACCORD SERONT SOUMISES AUX TRIBUNAUX COMPÉTENTS DE PARIS.

<div class="signature-block">
**Fait en 2 (deux) exemplaires originaux,
À Paris,
Le {{SIGNEDAT}},**
</div>

<div style="display: flex">
  <div style="border-top: solid 1px black; margin: 3em; box-sizing: border-box; flex-grow: 1">
  Pour SÉSAME
  </div>

  <div style="border-top: solid 1px black; margin: 3em; box-sizing: border-box; flex-grow: 1">
  Pour le CLIENT
  </div>
</div>
<div class="pagebreak"></div>

# Règlement intérieur

## ARTICLE 1 – OBJET

Le présent Règlement Intérieur fixe les règles à respecter au sein des locaux de SÉSAME.
Dans un souci de respect mutuel entre les praticiens, le CLIENT s’engage à en respecter l’ensemble de ses dispositions.


## ARTICLE 2 - CHAMP D'APPLICATION

Le présent Règlement Intérieur s'applique à tous CLIENTS et leurs éventuels préposés présents dans les locaux du PRESTATAIRE.


## ARTICLE 3 – COMMUNICATION

Pour une meilleure information, ce document sera communiqué en annexe de chaque conditions générales de mise à disposition de locaux et sera en outre consultable à l’accueil des locaux.


## ARTICLE 4 - INFORMATIONS OBLIGATOIRES

Tout CLIENT est appelé à fournir des renseignements d'ordre personnel et administratif le concernant (notamment ses diplômes, son assurance responsabilité civile professionnelle, son statut juridique) et à produire à l'appui de ces renseignements qui lui sont demandés toute pièce justificative à première demande.

Les fausses déclarations, omissions ou usages de faux documents, ayant eu une influence déterminante dans la relation commerciale entre les PARTIES peuvent entraîner la résiliation des conditions générales de mise à disposition et du ou des forfaits qui leur seraient annexés.


## ARTICLE 5 - HORAIRES DE LOCATION ET ACCUEIL

Le CLIENT peut réserver des salles individuelles selon les plages-horaire suivantes : du lundi au dimanche : de 8h à 22h

Un accueil physique sera proposé sur la majeure partie de ces plages-horaire.

Il est précisé que le samedi après-midi et le dimanche ainsi que sur les plages-horaire de 8h à 9h et de 21h à 22h, l’accueil physique ne sera pas assuré et que les jours fériés, l’accueil physique ne sera pas systématiquement assuré.


## ARTICLE 6 - RETARDS ET ANNULATIONS

Tout retard ou absence même de dernière minute devra être signalé auprès du PRESTATAIRE.

Etant précisé que le CLIENT pourra procéder à l’annulation de ses réservations dans les conditions fixées à l’article 4 de l’annexe forfait.


## ARTICLE 7 - ACCÈS AUX LOCAUX

7.1. Le CLIENT a accès aux locaux la semaine et le weekend avec les codes suivants :
- Porte rue : <strong>{{DOOR_CODE_BUILDING}}</strong>
- Porte vitrée : <strong>{{DOOR_CODE_BUILDING_2}}</strong>
Ces codes ne sont en principe actifs que de 22h à 8h en semaine, ainsi que du samedi 12h au lundi 8h.
S’ils venaient à changer, le CLIENT en sera avisé par mail.

7.2. En cas d’absence d’accueil physique, le CLIENT recevra par mail un code pour ouvrir la porte d’entrée du centre (au premier étage) et pourra le communiquer pour les besoins de ses rendez-vous du jour.
Le CLIENT s’engage à ne pas faire apparaître ces codes sur les supports de communication et ne les transmettre qu’en cas de besoin.


## ARTICLE 8 – LES OBLIGATIONS DU CLIENT

Dans un souci de respect mutuel entre les praticiens, Le CLIENT s’engage à observer le silence dans les parties communes, et ce principalement dans le couloir cabinets.

La séance et l’échange verbal doivent se terminer dans le cabinet porte fermée.

Le CLIENT doit ensuite « ouvrir le chemin » à son client jusqu’à l’accueil sans échanger de paroles.

Le CLIENT s’engage à refermer derrière lui la porte entre l’accueil et le couloir à chaque passage, et cela même lorsqu’il vient chercher son client dans l’accueil.

Le CLIENT s’engage à libérer sa cabine à l’heure (selon l’horloge de son téléphone) minimum 10 ou 15 secondes avant la fin de son créneau et à ne pas terminer sa séance dans la salle d’attente si le temps lui a manqué.


## ARTICLE 9 - USAGE DU MATÉRIEL DU PRESTATAIRE

Le CLIENT et ses éventuels préposés sont tenus de conserver en bon état, d'une façon générale, tout le matériel qui leur est confié en vue de l'exécution de son rendez-vous.

Le CLIENT aura la possibilité de modifier la disposition du mobilier du cabinet comme il le souhaite mais s’engage à restituer le cabinet dans la disposition où il l’a trouvé, cependant il devra le faire pendant les horaires de sa réservation et sans bruit.

Le CLIENT s’engage à ranger le matériel fourni par le PRESTATAIRE : plier les couvertures, ranger les coussins, enlever la têtière, rassembler ses déchets personnels et les placer dans la corbeille, débarrasser les carafes et verres utilisés lors d’une séance et les laver et ranger, aérer les locaux afin de rendre le cabinet disponible pour un autre praticien.

Il est indispensable d'aviser le PRESTATAIRE de toute défaillance ou défectuosité qui pourrait être constatée.

Les tables de massage doivent être remise à une hauteur médiane, 4ème ou 5ème cran.

Les serviettes usagées devront être déposées dans la panière située dans le foyer/cuisine.

Le CLIENT s’engage à fermer la porte de sa cabine lorsqu’il ouvre les fenêtres pour aérer côté rue (afin de réduire la diffusion du bruit de la circulation qui raisonne fortement dans le couloir). Il s’engage également à s’assurer de la fermeture des fenêtres lorsqu’il quitte un cabinet sur rue en fin de journée, afin de ne pas permettre une intrusion.

## ARTICLE 10 - USAGE DES LOCAUX DU PRESTATAIRE

Le PRESTATAIRE doit s’assurer que les locaux sont entretenus préalablement.

Cependant les locaux mis à disposition des CLIENTS notamment cabinets, espace foyer-cuisine, sanitaires, douche, lieux de travail ou lieux communs doivent être gardés propres par les CLIENTS.

## ARTICLE 11 - PRÉVENTION DES ACCIDENTS

Le CLIENT et ses éventuels préposés sont tenus d’observer auprès de leur clientèle et du PRESTATAIRE les mesures d’hygiène et de sécurité.

Il est obligatoire de mettre en œuvre toutes les mesures de sécurité et de protection individuelles ou collectives existantes et de respecter scrupuleusement les instructions sur ce point.

Il est interdit en particulier d'enlever ou de neutraliser des dispositifs de sécurité existants, sans fait justificatif.

Il est interdit de limiter l'accès aux matériels de sécurité (extincteurs, brancards, trousses de secours, etc), de les déplacer sans nécessité ou de les employer à un autre usage.

Toute violation de ces dispositions constitue une faute qui pourra entrainer la résiliation des conditions générales et du ou des forfaits qui leurs seraient annexés.

## ARTICLE 12 - INTERDICTION DE FUMER

En application du décret n°92-478 du 29 mai 1992, il est interdit de fumer dans tous les locaux fermés et couverts.

L'interdiction de fumer sur les lieux de travail s'applique également à l'utilisation de cigarettes électroniques.

<div class="signature-block">
Fait à Paris, le {{SIGNEDAT}},
</div>
`