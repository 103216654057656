"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientAuditProvider = exports.AuditProvider = exports.AuditTypes = exports.AuditEventTypes = void 0;
const rest_provider_1 = require("../utils/rest_provider");
exports.AuditEventTypes = [
    {
        id: "Audit::NewClient",
        color: "#9DAFFF",
        text: "Nouveau Client",
        category: "Clients"
    }, {
        id: "Audit::DisableClient",
        color: "#81C57A",
        text: "Client désactivé",
        category: "Clients"
    }, {
        id: "Audit::NewBooking",
        color: "#E9DEBB",
        text: "Nouvelle réservation",
        category: "Réservations"
    }, {
        id: "Audit::CancelBooking",
        color: "#FFCDF3",
        text: "Réservation annulée",
        category: "Réservations"
    }, {
        id: "Audit::72_96hCancelBooking",
        color: "#FFCDF3",
        text: "Réservation annulée (72-96h)",
        category: "Réservations"
    }, {
        id: "Audit::FreeQuotaConsumed",
        color: "#FFCDF3",
        text: "Consommation (Quota gratuit)",
        category: "Réservations"
    }, {
        id: "Audit::OutOfQuotaConsumed",
        color: "#FFCDF3",
        text: "Consommation (Hors quota)",
        category: "Réservations"
    }, {
        id: "Audit::ConfirmBooking",
        color: "#FFCDF3",
        text: "Réservation confirmée",
        category: "Réservations"
    }, {
        id: "Audit::MoveBooking",
        color: "#FFCDF3",
        text: "Réservation déplacée",
        category: "Réservations"
    }, {
        id: "Audit::ResizeBooking",
        color: "#FFCDF3",
        text: "Durée de réservation changée",
        category: "Réservations"
    },
    {
        id: "Audit::NewContract",
        color: "#FF9233",
        text: "Nouveau forfait",
        category: "Forfaits"
    }, {
        id: "Audit::ExpiredContract",
        color: "#FF9233",
        text: "Forfait expiré",
        category: "Forfaits"
    }, {
        id: "Audit::ConsumedContract",
        color: "#FF9233",
        text: "Forfait consommé",
        category: "Forfaits"
    },
    {
        id: "Audit::ManualOperations",
        color: "#FF9233",
        text: "Opérations manuelles",
        category: "Forfaits"
    },
];
exports.AuditTypes = {};
exports.AuditEventTypes.forEach(category => {
    exports.AuditTypes[category.id] = category.text;
});
exports.AuditProvider = new rest_provider_1.RestProvider({
    name: "audit",
    create: "",
    delete: "",
    index: "GET|/audits",
    new: "",
    show: "GET|/audits/:id",
    update: ""
});
exports.ClientAuditProvider = new rest_provider_1.RestProvider({
    name: "clientAudit",
    create: "",
    delete: "",
    index: "GET|/audits",
    new: "",
    show: "",
    update: ""
});
