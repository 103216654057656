"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentProvider = exports.ClientPaymentProvider = exports.paymentTypeToString = exports.PAYMENT_TYPES = void 0;
const rest_provider_1 = require("../utils/rest_provider");
exports.PAYMENT_TYPES = {
    "cash": "Espèces",
    "check": "Chèque",
    "transfert": "Virement bancaire",
    "card": "Par carte"
};
function paymentTypeToString(x) {
    return exports.PAYMENT_TYPES[x] || x;
}
exports.paymentTypeToString = paymentTypeToString;
exports.ClientPaymentProvider = new rest_provider_1.RestProvider({
    name: "client_payment",
    create: "",
    update: "",
    show: "",
    delete: "",
    index: "GET|/me/payments",
    new: ""
});
exports.PaymentProvider = new rest_provider_1.RestProvider({
    name: "payment",
    create: "POST|/payments",
    update: "PATCH|/payments/:id",
    show: "GET|/payments/:id",
    delete: "DELETE|/payments/:id",
    index: "GET|/payments",
    new: "GET/payments/new"
});
