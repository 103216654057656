"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoomProvider = void 0;
const rest_provider_1 = require("../utils/rest_provider");
exports.RoomProvider = new rest_provider_1.RestProvider({
    name: "room",
    create: "POST|/rooms",
    update: "PATCH|/rooms/:id",
    show: "GET|/rooms/:id",
    delete: "DELETE|/rooms/:id",
    index: "GET|/rooms",
    new: "GET/rooms/new"
});
