"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractProvider = exports.ContractFormProvider = void 0;
const rest_provider_1 = require("../utils/rest_provider");
exports.ContractFormProvider = new rest_provider_1.RestProvider({
    name: "contract_form",
    create: "POST|/contracts",
    update: "PATCH|/contracts/:id",
    show: "GET|/contracts/:id",
    delete: "DELETE|/contracts/:id",
    index: "GET|/contracts",
    new: "GET/contracts/new"
});
exports.ContractProvider = new rest_provider_1.RestProvider({
    name: "contract",
    create: "POST|/contracts",
    update: "PATCH|/contracts/:id",
    show: "GET|/contracts/:id",
    delete: "DELETE|/contracts/:id",
    index: "GET|/contracts",
    new: "GET/contracts/new"
});
