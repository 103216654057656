"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.delayed = void 0;
const DELAYED_IDS = {};
const delayed = function (id, callback, time = 150) {
    let tid = DELAYED_IDS[id];
    if (tid) {
        window.clearTimeout(tid);
    }
    DELAYED_IDS[id] = window.setTimeout(callback, time);
};
exports.delayed = delayed;
