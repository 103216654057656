"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommercialOfferProvider = void 0;
const rest_provider_1 = require("../utils/rest_provider");
exports.CommercialOfferProvider = new rest_provider_1.RestProvider({
    name: "commercial_offer",
    create: "POST|/commercial_offers",
    update: "PATCH|/commercial_offers/:id",
    show: "GET|/commercial_offers/:id",
    delete: "DELETE|/commercial_offers/:id",
    index: "GET|/commercial_offers",
    new: "GET/commercial_offers/new"
});
