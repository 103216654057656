"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientProvider = void 0;
const rest_provider_1 = require("../utils/rest_provider");
exports.ClientProvider = new rest_provider_1.RestProvider({
    name: "client",
    create: "POST|/clients",
    update: "PATCH|/clients/:id",
    show: "GET|/clients/:id",
    delete: "DELETE|/clients/:id",
    index: "GET|/clients",
    new: "GET/clients/new"
});
