"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TurnoverPage = void 0;
const m = require("mithril");
const app_1 = require("../../app");
const helpers_1 = require("../../utils/helpers");
const form_for_1 = require("../../widgets/form_for");
function renderTitle() {
    return [
        (0, form_for_1.columns)(m("h2", "Historique du chiffre d'affaire"), m("div", { style: (0, helpers_1.ifTrue)(!(0, helpers_1.isMobile)(), "text-align: right;") }, m(".tags.has-addons.is-pulled-right", m(".tag", "Seuil de rentabilité"), m(".tag.is-primary", "" + (0, helpers_1.renderMoney)(getRentabilityThreshold()), " €")))),
        m("hr")
    ];
}
function renderLegend() {
    return m("div", m(".legend", { style: "font-size: 0.75em;" }, m("sup", "[1] "), m("span", "Données importées de l'ancienne plateforme")), m(".legend", { style: "font-size: 0.75em;" }, m("sup", "[2] "), m("span", "Estimations basées sur les réservations à venir")));
}
function getRentabilityThreshold() {
    return parseFloat(app_1.App.getSettings("rentability_threshold", "22000"));
}
function renderRentabilityRate(x, period = 1) {
    if (typeof (x) !== "number") {
        return "-";
    }
    else {
        let rentability = (100 * parseFloat(x) / (period * getRentabilityThreshold()));
        if (rentability > 100) {
            return m("span.has-text-success", rentability.toFixed(0) + '%');
        }
        else {
            return m("span.has-text-danger", rentability.toFixed(0) + '%');
        }
    }
}
function renderYears() {
    let o = [];
    for (let i = new Date().getUTCFullYear();; i--) {
        if (i < 2015)
            break;
        o.push(renderYear(i));
    }
    return o;
}
function renderYear(yearNumber) {
    let totalRevenue = 0, totalConsumed = 0, totalPredicted = 0;
    return m("table.table.financial-year.is-striped", m("thead", m("tr", m("td", { colspan: 5, style: "text-align: center;" }, m("h2", `${yearNumber}`))), m("tr", m("th", ""), m("th", (0, helpers_1.ifTrue)((0, helpers_1.isMobile)(), "CA (TTC)", "Chiffre d'affaire (TTC)")), m("th", (0, helpers_1.ifTrue)((0, helpers_1.isMobile)(), "Cons.", "Consommé")), m("th", (0, helpers_1.ifTrue)((0, helpers_1.isMobile)(), "Prév.", "Prévu")), m("th", (0, helpers_1.ifTrue)((0, helpers_1.isMobile)(), "Rent.", "Rentabilité")))), m("tbody", (0, helpers_1.range)(1, 12).map(x => (0, helpers_1.getMonthFr)(x, (0, helpers_1.isMobile)())).map((monthName, idx) => {
        let mth = `${yearNumber}-${(0, helpers_1.padded)(idx - 12 + 2, 2)}`;
        let revenue = '-', consumed = '-', predicted = '-', isLegacy = false, isSimulated = false;
        if (data[mth])
            [revenue, consumed, predicted, isLegacy, isSimulated] = data[mth];
        totalRevenue += parseFloat(revenue) || 0;
        totalConsumed += parseFloat(consumed) || 0;
        totalPredicted += parseFloat(predicted) || 0;
        let legacyWarning = isLegacy ? m("sup", m("a", { title: "Données importées de l'ancienne plateforme" }, "1")) : undefined;
        let simulationWarning = isSimulated ? m("sup", m("a", { title: "Estimations basées sur les réservations à venir" }, "2")) : undefined;
        let predictedHtml;
        if (typeof (predicted) === 'number' && predicted != 0 && consumed != 0) {
            predictedHtml = m("td", (0, helpers_1.renderMoney)(predicted), m("small", ` (${(0, helpers_1.renderMoney)(consumed + predicted)})`), simulationWarning);
        }
        else {
            predictedHtml = m("td", (0, helpers_1.renderMoney)(predicted));
        }
        return m("tr", m("td", monthName), m("td", (0, helpers_1.renderMoney)(revenue), legacyWarning), m("td", (0, helpers_1.renderMoney)(consumed), legacyWarning), predictedHtml, m("td", renderRentabilityRate(revenue + predicted)));
    }), m("tr", m("td", m("strong", (0, helpers_1.ifTrue)((0, helpers_1.isMobile)(), "Σ", "TOTAL"))), m("td", m("strong", (0, helpers_1.renderMoney)(totalRevenue))), m("td", m("strong", (0, helpers_1.renderMoney)(totalConsumed))), m("td", m("strong", (0, helpers_1.renderMoney)(totalPredicted))), m("td", m("strong", renderRentabilityRate(totalRevenue, 12))))));
}
let data = {};
exports.TurnoverPage = {
    oncreate() {
        app_1.App.get("/stats/financial").then(x => data = x);
    },
    view() {
        return m(".content.container", { style: "padding: 0 1em;" }, renderTitle(), renderLegend(), renderYears());
    }
};
