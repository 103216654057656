"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.minutesToText = exports.numberToText = exports.moneyToText = void 0;
const FR_NUM_20 = [
    '', 'ET UN', 'DEUX', 'TROIS', 'QUATRE', 'CINQ', 'SIX', 'SEPT', 'HUIT', 'NEUF',
    'DIX', 'ONZE', 'DOUZE', 'TREIZE', 'QUATORZE', 'QUINZE', 'SEIZE', 'DIX-SEPT', 'DIX-HUIT', 'DIX-NEUF'
];
const FR_NUM_10 = [
    '',
    '',
    'VINGT',
    'TRENTE',
    'QUARANTE',
    'CINQUANTE',
    'SOIXANTE',
    'SOIXANTE',
    'QUATRE-VINGT',
    'QUATRE-VINGT',
];
function moneyToText(x) {
    let euros = Math.floor(x.raw / 100);
    let eurosStr = euros > 1 ? "EUROS" : "EURO";
    let cents = x.raw % 100;
    let showCents = cents != 0;
    let out = [];
    out.push(numberToText(euros));
    out.push(eurosStr);
    if (showCents) {
        out.push("ET");
        out.push(numberToText(cents));
        out.push("CENTIMES");
    }
    return out.join(" ");
}
exports.moneyToText = moneyToText;
function numberToText(x, empty_one = false) {
    if (x == 0)
        return 'ZERO';
    if (x == 1 && empty_one)
        return '';
    let arr = renderBillion(x)
        .concat(renderMillions(x))
        .concat(renderThousands(x))
        .concat(render100(x))
        .concat(render99(x));
    let text = arr.filter(x => x != '').join(" ");
    return text;
}
exports.numberToText = numberToText;
function minutesToText(totalMinutes) {
    let minutes = totalMinutes % 60;
    let hours = Math.floor(totalMinutes / 60);
    let out = numberToText(hours) + " HEURE";
    if (minutes !== 0) {
        out = out + ` ET ${numberToText(minutes)} MINUTES`;
    }
    return out;
}
exports.minutesToText = minutesToText;
function render99(x) {
    x = x % 100;
    if (x == 0) {
        return [];
    }
    if (x == 1) {
        return ['UN'];
    }
    if (x < 20) {
        return FR_NUM_20[x];
    }
    else {
        if (x >= 60 && x <= 79) {
            return [FR_NUM_10[6], FR_NUM_20[x - 60]];
        }
        else if (x >= 80 && x <= 99) {
            return [FR_NUM_10[9], FR_NUM_20[x - 80]];
        }
        else {
            return [FR_NUM_10[Math.floor(x / 10)], FR_NUM_20[x % 10]];
        }
    }
}
function renderBillion(x) {
    let num_billions = Math.floor(x / 1000000000);
    if (num_billions == 0)
        return [];
    return [numberToText(num_billions), "MILLIARD"];
}
function renderMillions(x) {
    let num_millions = Math.floor((x % 1000000000) / 1000000);
    if (num_millions == 0)
        return [];
    return [numberToText(num_millions), "MILLION"];
}
function render100(x) {
    let num = Math.floor((x % 1000) / 100);
    if (num == 0)
        return [];
    return [numberToText(num, true), "CENT"];
}
function renderThousands(x) {
    let num_millions = Math.floor((x % 1000000) / 1000);
    if (num_millions == 0)
        return [];
    return [numberToText(num_millions, true), "MILLE"];
}
