"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailedBookingProvider = exports.BookingProvider = void 0;
const rest_provider_1 = require("../utils/rest_provider");
exports.BookingProvider = new rest_provider_1.RestProvider({
    name: "booking",
    create: "POST|/bookings",
    update: "PATCH|/bookings/:id",
    show: "GET|/bookings/:id",
    delete: "DELETE|/bookings/:id",
    index: "GET|/bookings",
    new: "GET/bookings/new"
});
// Some trickery and sorcery
exports.DetailedBookingProvider = exports.BookingProvider;
