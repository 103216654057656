"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsProvider = void 0;
const rest_provider_1 = require("../utils/rest_provider");
exports.SettingsProvider = new rest_provider_1.RestProvider({
    name: "settings",
    create: "PATCH|/settings",
    update: "PATCH|/settings",
    index: "GET|/settings",
    delete: "",
    new: "",
    show: "GET|/settings",
});
