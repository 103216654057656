export default `
La présente ANNEXE est partie intégrante et soumise au contrat de mise à disposition de locaux n°{{contract_id}} signé entre le CLIENT et SÉSAME le {{created_at}}


##  ARTICLE 1 - FORFAIT {{total_hours}} HEURES

Le CLIENT souscrit à un forfait lui permettant de disposer des locaux pour {{total_hours}} ({{total_hours_text}}).

### 1.1 Délai d’utilisation
Le CLIENT est tenu d’utiliser ces heures sur une période d’utilisation de {{validity_period}} ({{validity_period_text}}) mois :
À partir du {{created_at}} et jusqu’au {{expires_at}}

## ARTICLE 2 - PRIX

Le prix du forfait est fixé à : {{value}} HT ({{value_text}} HORS TAXES) soit {{value_ttc}} TTC ({{value_ttc_text}} TOUTES TAXES COMPRISES).

Le CLIENT peut bénéficier de serviettes fournies par SÉSAME en contrepartie du paiement d’un supplément :
* 1€ (un euro) pour 1 (une) grande et 1 (une) petite serviette.

Le CLIENT est tenu au prix du forfait qu’il a souscrit quel que soit le nombre d’heures effectivement utilisées.

## ARTICLE 3 - MODALITÉS DE PAIEMENT

Le CLIENT paye le montant correspondant à son forfait et aux éventuels suppléments selon les modalités suivantes :

* Paiement en {{num_payments}}({{num_payments_text}}) fois:
{{list_payments}}

Quel que soit l’échéancier de paiement convenu entre les parties, le CLIENT est tenu de payer l’intégralité du forfait au terme de celui-ci.
Le CLIENT est tenu de payer aux échéances convenues et mentionnées sur sa facture.

En cas de retard de paiement, les intérêts légaux de retard seront dus par le CLIENT ainsi qu’une indemnité forfaitaire de 40€ (quarante euros) pour frais de recouvrement.


## ARTICLE 4 - CONDITIONS D’ANNULATION DES HEURES SOUSCRITES

### 4.1 Généralités

Le CLIENT aura le droit, si un de ses clients lui fait défaut, et uniquement dans ce cas, d’annuler ses rendez-vous pris sur
l’agenda en ligne de SESAME jusqu’à 1 (une) heure avant l’heure réservée.

En cas d’utilisation abusive de ce droit, y compris dans la période concernée par le quota (soit entre 1 heure et 6 jours avant le
créneau réservé), SESAME déduira du forfait le nombre d’heures nécessaires pour retrouver un rapport « heures
annulées/heures utilisées » afin qu’il soit au moins égal à 50% sur les 15 derniers jours, et cela même si le quota n’est pas
atteint. Les 50% des heures annulées non pénalisées seront soumis aux règles du quota.

### 4.2 Conditions d’annulation spécifiques au forfait souscrit

Le CLIENT peut annuler, sans frais, une heure réservée :
* Jusqu’à 4 (quatre) jours avant l’heure réservée ;
* Dans les 12 heures suivant la pose de la réservation, dans la limite d’une heure avant la réservation.

Un quota de {{total_hours_cancelable}} ({{total_hours_cancelable_text}}) annulables sans frais est appliqué entre 1 heure et 6 jours avant le créneau réservé.
Au-delà du quota, en considérant qu’une heure représente 1 point, le barème suivant sera appliqué:

* 3 jours avant le créneau réservé : - 0,4 point soit 40% du temps retenu
* 2 jours avant le créneau réservé : - 0,5 point soit 50% du temps retenu
* 1 jour avant le créneau réservé : - 0,6 point soit 60% du temps retenu

Les décimales restantes en fin de forfait sont reportées, le cas échéant, sur le contrat suivant.

## ARTICLE 5 - UTILISATION DES HEURES EN DEHORS DE LA PÉRIODE

Après la période d’utilisation, les heures souscrites mais non utilisées par le CLIENT sont considérées comme consommées.

Le CLIENT aura cependant la possibilité de les utiliser après la période d’utilisation aux conditions suivantes :
* facturation à hauteur de 14€ TTC (quatorze euros toutes taxes comprises) ;
* annulation uniquement jusqu'à 7 jours avant le rdv posé.

Le CLIENT est tenu de prolonger un minimum de 4 (quatre) heures.
`