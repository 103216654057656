"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminProvider = void 0;
const rest_provider_1 = require("../utils/rest_provider");
exports.AdminProvider = new rest_provider_1.RestProvider({
    name: "admin",
    create: "POST|/admins",
    delete: "DELETE|/admins/:id",
    index: "GET|/admins",
    new: "GET|/admins/new",
    show: "GET|/admins/:id",
    update: "PATCH|/admins/:id"
});
