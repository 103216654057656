"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrowserSettings = void 0;
/**
 * Store locally some settings
 */
exports.BrowserSettings = {
    save() {
        localStorage.setItem("__settings", JSON.stringify(this.data));
    },
    load() {
        this.data = JSON.parse(localStorage.getItem("__settings") || "{}");
    },
    set(key, value) {
        let oldData = this.data[key];
        this.data[key] = value;
        if (oldData != value) {
            this.save();
        }
        return value;
    },
    get(key, def = undefined) {
        return this.data[key] || def;
    },
    data: {}
};
exports.BrowserSettings.load();
