"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientAccountProvider = void 0;
const rest_provider_1 = require("../utils/rest_provider");
exports.ClientAccountProvider = new rest_provider_1.RestProvider({
    name: "client_account",
    create: "",
    update: "PATCH|/me/account",
    show: "GET|/me/account",
    delete: "",
    index: "",
    new: ""
});
