"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.copyToClipboard = void 0;
const ip = document.createElement("textarea");
ip.style.display = "none";
window.addEventListener("load", () => {
    document.body.appendChild(ip);
});
function copyToClipboard(content) {
    ip.value = content;
    ip.style.display = "block";
    window.setTimeout(() => {
        ip.select();
        ip.setSelectionRange(0, 99999); /*For mobile devices*/
        /* Copy the text inside the text field */
        document.execCommand("copy");
        ip.style.display = "none";
        alert("Copié dans le presse papier !");
    }, 0);
}
exports.copyToClipboard = copyToClipboard;
