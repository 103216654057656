"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueProvider = exports.listVenues = exports.loadVenues = exports.venuesForSelect = void 0;
const app_1 = require("../app");
const rest_provider_1 = require("../utils/rest_provider");
let venueListItems;
let venuesForSelectArr;
function venuesForSelect() {
    return venuesForSelectArr || [];
}
exports.venuesForSelect = venuesForSelect;
function loadVenues() {
    listVenues().then((vl) => {
        venuesForSelectArr = vl.map((v) => [v.id, v.name]);
    });
}
exports.loadVenues = loadVenues;
function listVenues() {
    if (venueListItems) {
        return new Promise((succ) => { succ(venueListItems); });
    }
    return app_1.App.get("/venues/list").then((data) => {
        return venueListItems = data;
    });
}
exports.listVenues = listVenues;
exports.VenueProvider = new rest_provider_1.RestProvider({
    name: "venue",
    create: "POST|/venues",
    update: "PATCH|/venues/:id",
    show: "GET|/venues/:id",
    delete: "DELETE|/venues/:id",
    index: "GET|/venues",
    new: "GET/venues/new"
});
