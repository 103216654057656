"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceProvider = void 0;
const rest_provider_1 = require("../utils/rest_provider");
exports.InvoiceProvider = new rest_provider_1.RestProvider({
    name: "invoice",
    create: "POST|/invoices",
    update: "PATCH|/invoices",
    show: "GET|/invoices/:id",
    delete: "DELETE|/invoices/:id",
    index: "GET|/invoices",
    new: "GET/invoices/new"
});
