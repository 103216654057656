"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnnounceProvider = void 0;
const rest_provider_1 = require("../utils/rest_provider");
exports.AnnounceProvider = new rest_provider_1.RestProvider({
    name: "announce",
    create: "POST|/announces",
    update: "PATCH|/announces/:id",
    show: "GET|/announces/:id",
    delete: "DELETE|/announces/:id",
    index: "GET|/announces",
    new: "GET/announces/new"
});
